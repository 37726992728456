import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, IconButton } from "@mui/material";
// import { CSVLink } from "react-csv";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import "./dancers.css";
import { appConfig, allowed } from "../config.js";
import Schedule from "../pages/Schedule";
import Playlist from "./Playlist";
import ContactInfo from "./ContactInfo";
import DancerFormField from "./DancerFormField.js";

const NODEURL = appConfig.NODEURL;

export default function RegisterForm(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loc, setLoc] = useState("");
  const earlyBirdDate = new Date("2024-10-03");
  const isEarlyBird = earlyBirdDate > new Date();

  const [price] = useState(isEarlyBird ? 120 : 135);
  const [studentPrice] = useState(isEarlyBird ? 80 : 90);
  const [total, setTotal] = useState(price);
  const [showForm, setShowForm] = useState(true);

  const prices = {
    Fleur: [120, 80, 135, 85],
    Monday: [10.7, 5.7, 10.7, 5.7],
    ThirdSaturday: [15.7, 8.7, 15.7, 8.7],
  };

  // JSON.stringify(loc)

  const [renderer, setRenderer] = useState("");
  const [fields, setFields] = useState(
    props.cartItems
      ? props.cartItems
      : [
          {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            student: false,
            housing: false,
            dietary: "omnivore",
            price: price,
            unit_amount: price,
            quantity: 1,
            name: "dance_admission",
            waltzattend: "select...",
            event: "Fleur",
          },
        ]
  );
  useEffect(() => {
    setLoc(allowed);
  }, []);

  // useEffect(() => {
  //   const allowed = [
  //     "192.168.1.66:3009",
  //     "localhost:3009",
  //     "lcd.contranooga.us",
  //   ];
  //   // setLoc(allo.find((el)=>{el===window.location.host})//window.location.host);
  //   setLoc(allowed.some((el) => el === window.location.host));
  //   // console.log(allowed.some((el) => el === window.location.host));
  //   // console.log(loc);
  // }, []);

  useEffect(() => {
    if (loc) {
      setFields([
        {
          firstname: "Richard",
          lastname: "Lanham",
          email: "richard.lanham@gmail.com",
          phone: "",
          student: false,
          housing: false,
          dietary: "omnivore",
          price: price,
          unit_amount: price,
          quantity: 1,
          name: "dance_admission",
          waltzattend: "select...",
          event: "Fleur",
        },
      ]);
    }
  }, [loc]);

  const setEvent = (index, event) => {
    // console.log(fields[index].student);
    const factr = isEarlyBird ? 0 : 2;
    // console.log(
    //   prices[event.target.value][fields[index].student ? 1 + factr : 0 + factr]
    // );
    fields[index].price =
      prices[event.target.value][fields[index].student ? 1 + factr : 0 + factr];
    fields[index].unit_amount =
      prices[event.target.value][fields[index].student ? 1 + factr : 0 + factr];

    selectChange(index, event);
  };
  // setFields(props.fields);
  const selectChange = (index, event) => {
    if (showPlayer) {
      closePlayer();
    }
    console.log("selectChange");
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };
  const [showHeader, setShowHeader] = useState(true);
  const [showDoc, setShowDoc] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const usPhoneNumberRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const [valMsg, setValMsg] = useState({});
  const [showPlayer, setShowPlayer] = useState(false);

  // setFields(props.fields);
  const handleChange = (event, index) => {
    // console.log(event);
    if (showPlayer) {
      closePlayer();
    }
    try {
      const newFields = [...fields];
      if (event.target.name === "housing") {
        document.getElementById(`email${index}-label`).innerHTML =
          "Email (optional)";
        document.getElementById(`phone${index}-label`).innerHTML =
          "Phone (optional)";

        document.getElementById(`email${index}`).title = "Email (optional)";
        document.getElementById(`phone${index}`).title = "Phone (optional)";
      }

      if (event.target.name === "housing" && event.target.checked) {
        document.getElementById(`email${index}-label`).innerHTML =
          "Email Required (or Phone)";
        document.getElementById(`phone${index}-label`).innerHTML =
          "Phone Required (or Email)";

        document.getElementById(`email${index}`).title =
          "Email or Phone is required";
        document.getElementById(`phone${index}`).title =
          "Phone or Email is required";
      }
      if (event.target.name === "student" || event.target.name === "housing") {
        newFields[index][event.target.name] = event.target.checked;
        // ? true
        // : false;

        if (event.target.name === "student") {
          const thisField = fields[index];
          //console.log(thisField.event);
          const factr = isEarlyBird ? 0 : 2;
          console.log(
            prices["Fleur"][event.target.checked ? 1 + factr : 0 + factr]
          );
          newFields[index]["price"] =
            prices[thisField.event][
              event.target.checked ? 1 + factr : 0 + factr
            ];
          newFields[index]["unit_amount"] =
            prices[thisField.event][
              event.target.checked ? 1 + factr : 0 + factr
            ];
        }
      } else {
        newFields[index][event.target.name] = event.target.value;
      }
      setFields(newFields);
      const totalAmount = newFields
        .reduce((total, item) => total + item.price * 1, 0)
        .toFixed(2);
      // const totalAmount = newFields.reduce((total, item) => total + (item.price * 1), 0).toFixed(2);
      setTotal(Number(totalAmount));
    } catch (err) {
      console.log(err);
    }
  };

  const closePlayer = () => {
    setShowPlayer(false);
  };

  const openPlayer = () => {
    setShowPlayer(true);
    setShowDoc(false);
  };

  const openDoc = () => {
    setShowPlayer(false);
    setShowDoc(true);
  };

  const removeField = (index) => {
    const xPrice = fields[index].price;
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    const totalAmount = newFields
      .reduce((total, item) => total + item.price * 1, 0)
      .toFixed(2);
    setTotal(Number(totalAmount));
  };

  const addFields = () => {
    const lastEvent = fields[fields.length - 1].event || "Fleur"; // for multi event setup to insert same as last setErrormsg("Check errors");
    setFields((prevFields) => {
      const newFields = [
        ...prevFields,
        {
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          student: fields[fields.length - 1].student,
          housing: false,
          dietary: "omnivore",
          price: fields[fields.length - 1].price,
          unit_amount: fields[fields.length - 1].price,
          quantity: 1,
          name: "dance_admission",
          waltzattend: "select...",
          event: lastEvent,
        },
      ];
      const totalAmount = newFields
        .reduce((total, item) => total + item.price * 1, 0)
        .toFixed(2);
      setTotal(Number(totalAmount));
      return newFields;
    });
  };

  // const errors = {
  // 0: [120, 80, 135, 85],
  // 1: [10.7, 5.7, 10.7, 5.7],
  // 2: [15.7, 8.7, 15.7, 8.7],
  // };
  const [errs, setErrs] = useState({});

  const validateBoxes = () => {
    // setDeferLoading(false);
    // console.log("Dancers");
    setRenderer("");
    const _er = {};
    setErrs(_er);
    try {
      for (var i = 0; i < fields.length; i++) {
        let msg = [];
        document.getElementById("email" + i).style.border = "none";
        document.getElementById("phone" + i).style.border = "none";
        document.getElementById("phone" + i).style.border = "none";
        document.getElementById("email" + i).style.border = "none";
        document.getElementById("lastname" + i).style.border = "none";
        document.getElementById("firstname" + i).style.border = "none";

        if (fields[i].housing) {
          if (
            document.getElementById("email" + i).value === "" &&
            document.getElementById("phone" + i).value === ""
          ) {
            document.getElementById("email" + i).style.border = "3px solid red";
            document.getElementById("phone" + i).style.border = "3px solid red";
            msg.push("Email or Phone are required if Housing is requested");
          }
        }
        if (fields[i].firstname === "") {
          document.getElementById("firstname" + i).style.border =
            "3px solid red";
          msg.push("First Name required");
        }
        if (fields[i].lastname === "") {
          document.getElementById("lastname" + i).style.border =
            "3px solid red";
          msg.push("Last Name required");
        }
        if (fields[i].email) {
          if (!emailRegex.test(fields[i].email)) {
            document.getElementById("email" + i).style.border = "3px solid red";
            msg.push("Email invalid");
          }
        }

        if (fields[i].phone) {
          if (!usPhoneNumberRegex.test(fields[i].phone)) {
            document.getElementById("phone" + i).style.border = "3px solid red";
            msg.push("Phone invalid");
          }
        }
        if (msg.length > 0) {
          const _errs = errs;
          _errs[i] = msg;
          setErrs(_errs);
        }
      }
    } catch (err) {
      // console.log(err);
    }

    const msgs = valMsg;

    // console.log(errs);
    // console.log(errs[0]);
    setRenderer(Math.random().toString());
    return;

    // if (Object.keys(errs).length > 0) {
    //   setRenderer(Math.random().toString());
    //   setErrormsg("Check errors");
    //   return;
    // }
    // setShowHeader(false);
    // setShowForm(false);
    // setShowPlayer(false);
    // setShowDoc(false);
    // // setShowConf(false);
    // setErrormsg("");
    // navigate("/checkout/", {
    //   state: { price: price, amount: 1, cartItems: fields },
    // });
  };

  const displayButtons = () => {
    // setDeferLoading(false);
    console.log("Dancers");
    setRenderer("");
    const _er = {};
    setErrs(_er);
    try {
      for (var i = 0; i < fields.length; i++) {
        let msg = [];
        document.getElementById("email" + i).style.border = "none";
        document.getElementById("phone" + i).style.border = "none";
        document.getElementById("phone" + i).style.border = "none";
        document.getElementById("email" + i).style.border = "none";
        document.getElementById("lastname" + i).style.border = "none";
        document.getElementById("firstname" + i).style.border = "none";

        if (fields[i].housing) {
          if (
            document.getElementById("email" + i).value === "" &&
            document.getElementById("phone" + i).value === ""
          ) {
            document.getElementById("email" + i).style.border = "3px solid red";
            document.getElementById("phone" + i).style.border = "3px solid red";
            msg.push("Email or Phone are required if Housing is requested");
          }
        }
        if (fields[i].firstname === "") {
          document.getElementById("firstname" + i).style.border =
            "3px solid red";
          msg.push("First Name required");
        }
        if (fields[i].lastname === "") {
          document.getElementById("lastname" + i).style.border =
            "3px solid red";
          msg.push("Last Name required");
        }

        if (i === 0 && fields[i].email === "") {
          // if (!emailRegex.test(fields[i].email)) {
          document.getElementById("email" + i).style.border = "3px solid red";
          msg.push("This email is required");
          //}
        }
        if (fields[i].email) {
          if (!emailRegex.test(fields[i].email)) {
            document.getElementById("email" + i).style.border = "3px solid red";
            msg.push("Email invalid");
          }
        }

        if (fields[i].phone) {
          if (!usPhoneNumberRegex.test(fields[i].phone)) {
            document.getElementById("phone" + i).style.border = "3px solid red";
            msg.push("Phone invalid");
          }
        }
        if (msg.length > 0) {
          const _errs = errs;
          _errs[i] = msg;
          setErrs(_errs);
        }
      }
    } catch (err) {
      // console.log(err);
    }

    const msgs = valMsg;

    // console.log(errs);
    // console.log(errs[0]);

    if (Object.keys(errs).length > 0) {
      setRenderer(Math.random().toString());
      setErrormsg("Check errors");
      return;
    }
    setShowHeader(false);
    setShowForm(false);
    setShowPlayer(false);
    setShowDoc(false);
    // setShowConf(false);
    setErrormsg("");
    localStorage.setItem("cartItems", fields);
    navigate("/checkout/", {
      state: { price: price, amount: 1, cartItems: fields, allowed: loc },
    });
  };

  // setFields(props.fields);

  const RowErrors = ({ index, errs }) => {
    return (
      <div
        style={{
          display: errs[index] ? "block" : "none",
          margin: "auto",
          width: 300,
          padding: 10,
          borderRadius: 50,
          boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }}
      >
        {errs[index] &&
          errs[index].map((msg, index) => {
            return <div key={index}>{msg}</div>;
          })}
      </div>
    );
  };

  return (
    <Box>
      <ContactInfo />
      <div style={{ display: showForm ? "block" : "none" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "fit-content",
            margin: "auto",
          }}
        >
          {fields.map((field, index) => (
            <Box
              key={"box" + index}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                borderRadius: 2,
                border: `2px solid ${theme.palette.primary.dark}`,
                padding: 2,
              }}
            >
              <DancerFormField
                key={index}
                field={field}
                index={index}
                handleChange={(event, index) => handleChange(event, index)}
                validateBoxes={validateBoxes}
              />
              <div
                style={{
                  paddingTop: 13,
                  // marginTop: 5,
                  fontWeight: "bold",
                  ...theme.typography.h5,
                }}
              >
                ${field.price}
              </div>

              <RowErrors index={index} errs={errs} />

              {index > 0 ? (
                <IconButton
                  title="delete dancer"
                  onClick={() => removeField(index)}
                  aria-label="delete"
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    color: "#fff",
                    margin: 5,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
              <div
                style={{
                  width: "100%",
                  display: "none",
                  borderBottom: `5px solid ${theme.palette.secondary.dark}`,
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              ></div>
            </Box>
          ))}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              flexBasis: 50,
              flexGrow: 0,
              flexShrink: 1,
              flexWrap: "wrap",
              // width: 500,
              margin: "auto",
            }}
          >
            <Button
              style={{
                // width: "10vw",
                // minWidth: 100,
                // maxWidth: 150,
                // textTransform: "capitalize",

                color: "#fff",
                ...theme.typography.button,
                backgroundColor: theme.palette.success.main,
              }}
              variant="contained"
              color="secondary"
              onClick={addFields}
            >
              Add Dancer
            </Button>

            <IconButton
              style={{
                width: "10vw",
                minWidth: 100,
                maxWidth: 150,
                textTransform: "capitalize",
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 10,
              }}
              onClick={displayButtons}
              variant="contained"
            >
              Check Out
              <ShoppingCartCheckoutIcon />
            </IconButton>
            <div
              style={{
                paddingTop: 20,
                height: 40,
                // lineHeight: ".8em",
                fontWeight: "bold",
                borderBottom: "5px double black",
                ...theme.typography.h4,
              }}
            >
              total:${total}
            </div>
          </div>
        </Box>

        <div
          className="noprint"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            margin: "auto",
            marginTop: 10,
            gap: 10,
          }}
        >
          <div style={{ border: "1px solid black", width: "100%" }}></div>

          <div style={{ display: "flex", gap: 3 }}>
            {showDoc ? (
              <Button
                title="close schedule"
                onClick={() => setShowDoc(false)}
                aria-label="close schedle"
                style={{
                  ...theme.typography.button,
                  backgroundColor: theme.palette.info.main,
                  color: theme.palette.info.contrastText,
                }}
              >
                <CloseIcon />
                close schedule
              </Button>
            ) : (
              <a
                style={{ ...theme.typography.button }}
                onClick={openDoc}
                href="#fleur-de-lis-fling-2023-schedule"
              >
                Schedule
              </a>
            )}

            {showPlayer ? (
              <Button
                name="fleur-de-lis-fling-2023-schedule"
                style={{
                  ...theme.typography.button,
                  backgroundColor: theme.palette.info.main,
                  color: theme.palette.info.contrastText,
                }}
                onClick={() => closePlayer()}
                // onClick={() => setShowDoc(true)}
                href="#fleur-de-lis-fling-2023-schedule"
              >
                <CloseIcon />
                Close Player
              </Button>
            ) : (
              <a
                href="#playerLoc"
                style={{ ...theme.typography.button }}
                onClick={openPlayer}
              >
                {appConfig.PLAYERNAME}
              </a>
            )}
          </div>
        </div>
      </div>
      {showPlayer && (
        <div>
          <div id="playerLoc"></div>
          <div className="noprint" style={{ fontSize: 24 }}>
            {appConfig.PLAYERNAME}
          </div>

          <Playlist
            showPlayer={showPlayer}
            play={[
              {
                url: "",
                label: "",
              },
              {
                url: "https://youtu.be/FnH9Ie3ezTg",
                label: "Saturday One",
              },
              {
                url: "https://youtu.be/6d3GKYzFbuI",
                label: "Saturday Two",
              },
              {
                url: "https://youtu.be/IEPflexB8nQ",
                label: "Saturday Three",
              },
              {
                url: "https://youtu.be/8zZZicFodms",
                label: "Friday One",
              },
              {
                url: "https://youtu.be/Ip-HIXcUtXw",
                label: "Friday Two",
              },
              {
                url: "https://youtu.be/kDO72-R3tOk",
                label: "Friday Three",
              },
              {
                url: "https://youtu.be/jxLG3eQ-dSA",
                label: "Friday Four",
              },
              {
                url: "https://youtu.be/S-MI7I5A0h8",
                label: "Friday Five",
              },
            ]}
          />
        </div>
      )}

      <div
        style={{
          backgroundColor: "#fff",
          marginTop: 40,
          display: showDoc ? "block" : "none",
        }}
      >
        <div id="fleur-de-lis-fling-2023-schedule" />
        <div
          style={{
            width: "100vw",
            position: "relative",
            // marginTop: 20,
            display: showPlayer ? "block" : "none",
          }}
        ></div>
        <Schedule />
      </div>
    </Box>
  );
}
