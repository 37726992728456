import React, { useState, useEffect, useRef } from "react";

import { useTheme, styled } from "@mui/material/styles";

import { Button, Box } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// const NODEURL = appConfig.NODEURL;;
function Schedule2023() {
  window.location.href = "/schedule_2023.html";
  return null;
}
export default function FlingInfo() {
  const theme = useTheme();

  const earlyBirdDate = new Date("2024-10-03");
  const isEarlyBird = earlyBirdDate > new Date();

  const [price, setPrice] = useState(isEarlyBird ? 120 : 135);
  const [studentPrice, setStudentPrice] = useState(isEarlyBird ? 80 : 90);

  const lateBird = [90, 135];

  const [showHeader, setShowHeader] = useState(true);

  const StyledHeader = styled("div")(({ theme }) => ({
    ...theme.typography.h4,
    width: "fit-content",

    margin: "auto",
    // marginTop: 5,
    // marginBottom: 5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    padding: 15,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
  }));

  const StyledInfo = styled("div")(({ theme }) => ({
    ...theme.typography.h5,
    width: "fit-content",
    margin: "auto",
    // marginTop: 20,
    padding: 10,
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "rgba(0, 0, 0, 0.15) 3.95px 3.95px 2.6px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      padding: 3,
      // margin: 4,
    },
    [theme.breakpoints.down("md")]: {
      // padding: 3,
      // margin: 2,
      // fontSize: "1em",
    },
  }));

  const Details = () => {
    const [show, setShow] = useState(false);
    if (!show) {
      return (
        <Button
          style={{
            ...theme.typography.button,
            ...theme.typography.h5,
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.shadows[12],
            width: "fit-content",
            height: 50,
            marginLeft: 15,
            boxShadow: `inset 0.05em 0.05em 0.05em 0 rgba(255,255,255,0.5), inset -0.05em -0.05em 0.05em 0 rgba(0,0,0,0.5)`,
          }}
          onClick={() => setShow(true)}
        >
          details...
        </Button>
      );
    }
    return (
      <StyledInfo
        style={{
          position: "relative",
          left: 0,
          top: 0,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <div>
          <Button
            style={{
              ...theme.typography.button2,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              boxShadow: `inset 0.05em 0.05em 0.05em 0 rgba(255,255,255,0.5), inset -0.05em -0.05em 0.05em 0 rgba(0,0,0,0.5)`,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onClick={() => setShow(false)}
          >
            <CloseIcon />
          </Button>
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>bands</span> Electric Eel and Double
          Trouble
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>callers</span> Bob Isaacs and River
          Rainbowface
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>sound</span> Ed Howe
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>singing</span> River Rainbowface and
          Callie Allison
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>location</span> 1228 East
          Breckenridge St, Louisville KY 40204
        </div>
      </StyledInfo>
    );
  };

  return (
    <div
      style={{
        display: showHeader ? "flex" : "none",

        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 20,
      }}
    >
      <StyledHeader
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <i>Fleur de Lis Fling</i> November 15th-17th, 2024
        <Details />
      </StyledHeader>

      <StyledHeader>Registration is Open!</StyledHeader>

      <StyledInfo style={{ ...theme.typography.h6 }}>
        <div>
          {isEarlyBird
            ? "Early bird pricing through " +
              new Date(earlyBirdDate - 1).toDateString("en-US", {
                timeZone: "UTC",
              })
            : ""}
        </div>
        <div>
          price: ${price}.00, student: ${studentPrice}{" "}
        </div>
        <div>
          {isEarlyBird
            ? `After  ${new Date(earlyBirdDate - 1).toDateString("en-US", {
                timeZone: "UTC",
              })} the prices are $${lateBird[1]} / $${lateBird[0]}`
            : ""}
        </div>
      </StyledInfo>
    </div>
  );
}
